<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page" />
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import { ActivityLogService as Service, UserService } from '@/services'
export default {
  name: 'ActivityLogList',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  mounted(){
    this.getUsers()
  },
  methods: {
    getUsers(){
      UserService.list({type: 'PANEL'}).then(({data: {docs}}) => {
        this.table.filter.push({
          type: 'select',
          field: 'createdBy',
          name: 'Oluşturan',
          data: docs.map(user => ({uuid: user.uuid, name: user.username}))
        })
      })
    }
  },
  data() {
    return {
      table: {
        page: {
          title: 'İşlem Logları',
          icon: 'account-multiple',
          actions: [
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => { this.$refs.table.loadAsyncData() }
            }
          ]
        },
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'model',
              label: 'Model',
              sortable: true
            },
            {
              field: 'modelId',
              label: 'Model ID',
              sortable: true,
              column: 'row',
              renderHtml: true,
              viewMethod: val => {
                if (val.model === 'User') {
                  return `<a href="/definition/user-create/${val.modelId}" target="_blank">${val.modelId}</a>`
                } else if (val.model === 'Bank') {
                  return `<a href="/definition/bank-create/${val.modelId}" target="_blank">${val.modelId}</a>`
                } else if (val.model === 'BankAccount') {
                  return `<a href="/definition/bank-account-create/${val.modelId}" target="_blank">${val.modelId}</a>`
                } else if (val.model === 'Transactions') {
                  return `${val.modelId}`
                }
              }
            },
            {
              field: 'type',
              label: 'İşlem',
              sortable: true
            },
            {
              field: 'data',
              label: 'Veri',
              sortable: true,
              column: 'row',
              renderHtml: true,
              viewMethod: val => {
                let returnData = ''
                for (let key in val.data) {
                  if (typeof val.data[key] === 'object' && val.data[key] !== null) {
                    returnData += `<b>${key}:</b> ${JSON.stringify(val.data[key].old)} -> ${JSON.stringify(val.data[key].new)}<br>`
                  } else {
                    returnData += `<b>${key}:</b> ${val.data[key].old} -> ${val.data[key].new}<br>`
                  }
                }
                return returnData
              }
            },
            {
              field: 'creator.username',
              label: 'Oluşturan'
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTimeS)
            }
          ]
        },
        filter: [
          {
            type: 'select',
            field: 'model',
            name: 'Model',
            data: [
              {uuid: 'User', name: 'Kullanıcı'},
              {uuid: 'Bank', name: 'Banka'},
              {uuid: 'BankAccount', name: 'Banka Hesabı'},
              {uuid: 'Transactions', name: 'Yatırım / Çekim'},
            ]
          },
          {
            type: 'input',
            field: 'modelId',
            name: 'Model ID'
          },
          {
            type: 'select',
            field: 'type',
            name: 'İşlem',
            data: [
              {uuid: 'DELETE', name: 'Silme'},
              {uuid: 'UPDATE', name: 'Güncelleme'},
            ]
          },
          {
            type: 'datepicker',
            field: 'createdAt',
            name: 'Kayıt T.',
            value: null
          }
        ]
      }
    }
  }
}
</script>
